<template>
  <!--升级会员-->
  <div style="padding: 20px;">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">升级会员</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
      <!--<el-card shadow="never" style="margin-top: 20px;">-->
      <!-- -->
      <!--</el-card>-->

      <div style="margin-top: 60px;">
        <div style="width: 60%;margin: auto;">
          <!--vue绑定背景图片:style="{backgroundImage :`url(${img})`}"-->
          <div class="bg" :style="{backgroundImage :`url(${img})`}">
            <img src="@/assets/images/home/gerenzhongxin/vip.png" alt="" style="width: 309px;height: 54px;">
            <div class="bg_b">
              <img src="@/assets/images/home/gerenzhongxin/huangguan.png" alt="" style="width: 478px;height: 202px;position: absolute;top: -135px;right: 65px;">

              <div v-if="buzhou == 0">
                <div style="width: 74%;margin: auto;">
                  <div class="kuai" v-for="(item,index) in dataList" :key="index" :class="{kuai1:index==isFangshi}" @click="activeItem(item,index)">
                    <!--<div class="xiaobiaozhi">-->
                    <!--  {{item.biaozhi}}-->
                    <!--</div>-->
                    <div style="font-size: 20px;font-weight: 500;">{{item.title}}</div>
                    <!--<div>-->
                    <!--  <span style="font-size: 24px;">￥</span>-->
                    <!--  <span style="font-size: 60px">{{item.jiage}}</span>-->
                    <!--</div>-->
                    <!--<div style="margin-top: 10px;">-->
                    <!--  <span style="font-size: 20px;font-weight: 400;color: #868792;text-decoration: line-through;">原价￥28.9元</span>-->
                    <!--</div>-->
                    <img v-if="isFangshi == index" src="@/assets/images/home/order/biaozhi.png" alt="" style="width: 26px;height: 26px;position: absolute;bottom: 0;right: 0">
                  </div>
                </div>

                <div style="width: 72%;margin: auto;margin-top: 20px;" v-if="dataList.length != 0">
                  <div style="display: flex;align-items: center;">
                    <span style="width: 5px;height: 19px;background: #a3a4b2;margin-right: 10px;"></span>
                    <span style="font-size: 20px;font-weight: 700;color: #202033;">选择时长</span>
                  </div>
                  <div style="margin-top: 10px;">
                    <div v-for="(item,index) in dataList" :key="index" v-if="isFangshi == index">
                      <div class="kuai2" v-for="(it,idx) in item.vip_day" :key="idx" :class="{kuai3:idx==isShow}" @click="activeItem1(it,idx)">
                        <!--<span>{{item.label}}</span>-->
                        <span>{{it.day}} 年 金额：{{it.price}}</span>
                        <img v-if="isShow == idx" src="@/assets/images/home/order/biaozhi.png" alt="" style="width: 26px;height: 26px;position: absolute;bottom: 0;right: 0">
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="dataList.length === 0">
                  <el-empty description="暂无数据"></el-empty>
                </div>

                <!--<div style="width: 62%;margin: auto;margin-top: 20px;">-->
                <!--  <div style="background: #f7f7f7;border-radius: 5px;padding: 10px;display: flex;align-items: center;">-->
                <!--    <img src="@/assets/images/home/gerenzhongxin/huiyuan.png" alt="" style="width: 23px;height: 20px;">-->
                <!--    <span style="font-size: 16px;font-weight: 400;color: #b68759;margin-left: 10px;">支付19.9元成为年卡会员，过期自动失效，可更复购买，</span>-->
                <!--    <span style="font-size: 16px;font-weight: 400;color: #FF9B05;margin-left: 10px;">自动续期</span>-->
                <!--  </div>-->
                <!--</div>-->

                <div style="width: 62%;margin: auto;margin-top: 20px;text-align: center;" v-if="dataList.length != 0">
                  <el-button style="background: #FF9B05!important;border-color: #FF9B05!important;color: #FFFFFF!important;width: 50%;" @click="To">同意协议并立即开通会员</el-button>
                </div>

                <!--<div style="width: 62%;margin: auto;margin-top: 20px;text-align: center;">-->
                <!--  <span style="font-size: 14px;font-weight: 400;color: #242832;">开通会员前请务必仔细阅读</span>-->
                <!--  <span style="font-size: 14px;font-weight: 400;color: #FF9B05;margin-left: 10px;cursor: pointer;">《会员服务协议》</span>-->
                <!--</div>-->
              </div>

              <div v-if="buzhou == 1">
                <div>
                  <span style="font-size: 24px;font-weight: 700;color: #202033;">您选择的是XX会员,</span>
                  <span style="font-size: 24px;font-weight: 700;color: #FF9B05;">需支付100元</span>
                  <span style="font-size: 24px;font-weight: 700;color: #202033;">,请选择支付方式:</span>
                </div>
                <div style="width: 62%;margin: auto;margin-top: 20px;">
                  <div class="kuaiyuansu" v-for="(item,index) in zhifuList" :key="index" :class="{kuaiyuansu1:index==isZhifu}" @click="activeItem2(item,index)">
                    <img :src="item.img" alt="" style="width: 82px;height: 82px;">
                    <span style="display: block;margin-top: 20px;">{{item.label}}</span>
                    <img v-if="isZhifu == index" src="@/assets/images/home/order/biaozhi.png" alt="" style="width: 26px;height: 26px;position: absolute;bottom: 0;right: 0">
                  </div>
                </div>
                <div style="width: 62%;margin: auto;margin-top: 20px;text-align: center;">
                  <el-button style="background: #FF9B05!important;border-color: #FF9B05!important;color: #FFFFFF!important;width: 50%;" @click="nextStep">下一步</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--支付弹窗-->
    <upgradeMember-edit :data="current" :visible.sync="showEdit"/>

  </div>
</template>
<script>
import UpgradeMemberEdit from './components/upgradeMember-edit.vue'
import {member_list, member_pay} from "@/api/center";
import {resource_buy} from "@/api/orderModule";

export default {
  components:{
    UpgradeMemberEdit
  },
  data(){
    return{
      img:require('../../assets/images/home/yunli/bg.png'),

      form:{},

      isFangshi:0,
      fangshiList:[
        {
          jiage:'19.9',
          title:'白金年卡',
          biaozhi:'会员专享'
        },
        {
          jiage:'49.9',
          title:'黄金年卡',
          biaozhi:'超值优惠'
        },
        {
          jiage:'69.9',
          title:'钻石年卡',
          biaozhi:'限时5天'
        },
      ],

      isShow:0,
      timeList:[
        {
          label:'1年',
        },
        {
          label:'2年',
        },
        {
          label:'3年',
        },
        {
          label:'4年',
        },
        {
          label:'5年',
        },
      ],

      buzhou:0,

      zhifuList:[
        {
          label:'微信支付',
          img:require('../../assets/images/home/gerenzhongxin/weixinzhifu.png'),
        },
        {
          label:'支付宝支付',
          img:require('../../assets/images/home/gerenzhongxin/zhifubaozhifu.png'),
        },
        {
          label:'银行汇款',
          img:require('../../assets/images/home/gerenzhongxin/yinhanghuikuan.png'),
        },
      ],
      isZhifu:0,

      current:null,
      showEdit:false,

      // 会员列表
      dataList: [],
      // 默认会员选择
      vipdata: {},
      // 默认时间数据 金额数据
      paymentdata: {},

    }
  },

  mounted() {
    console.log(this.$route.query)

    this.getList();
  },

  methods:{
    //获取会员栏目列表
    getList(){
      member_list().then(res => {
        console.log(res)
        this.dataList = res.data;
        this.quanyi = [];
        this.dataList.forEach(item => {
          let data = {
            title: item.title,
            rights: item.rights
          }
          this.quanyi.push(data)
          console.log(this.quanyi)
        })


        // 默认选择的会员
        this.vipdata = this.dataList[0];
        // 默认选择
        this.paymentdata = this.dataList[0].vip_day[0];
        console.log(this.paymentdata)
      })
    },

    //点击返回按钮
    returnPage(){
      this.$router.go(-1);
    },

    //选择方式
    activeItem(item, index) {
      this.isFangshi = index;
      this.vipdata = item;
    },

    // 选择时间
    activeItem1(it, idx) {
      console.log(it)
      console.log(idx)
      // 支付数据
      this.paymentdata = it;
      // 显示选中
      this.isShow = idx;
    },

    //点击开通
    To(){
      // this.buzhou = 1;
      // 会员购买支付接口
      let data = {
        id: this.vipdata.id,
        day: this.paymentdata.day,
        // return_url: window.location.href
        return_url: this.$route.query.href
      }
      member_pay(data).then(res => {
        if(res.code === 200){
          console.log(res)
          window.location.href = res.data.return;
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

    activeItem2(item,index){
      this.isZhifu = index;
    },

    //点击下一步
    nextStep(){
      if(this.isZhifu === 0){
        this.current = '微信支付'
      }else if(this.isZhifu === 1){
        this.current =  '支付宝支付'
      }else {
        this.current = '银行汇款'
      }
      this.showEdit = true;
    },

  },

}
</script>

<style lang="scss" scoped>
.bg{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 50px 30px 50px 30px;
  position: relative;
}
.bg_b{
  background: #FFFFFF;
  padding: 60px 20px 20px 20px;
  border-radius: 40px;
  position: absolute;
  left: 0;
  top: 140px;
  width: 100%;
}

.kuai{
  background: #ffffff;
  border: 1px solid #ededf5;
  border-radius: 6px;
  //padding: 20px 30px 20px 30px;
  //width: 140px;
  margin: 10px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  //padding: 40px 30px 20px 30px;
  padding: 20px 30px;
}
.kuai:hover{
  background: #FFFAF2;
  border-color: #FAD08D;
}
.kuai1{
  background: #FFFAF2;
  border-color: #FAD08D;
  color: #A15100;
}
.xiaobiaozhi{
  padding: 5px 10px 5px 10px;
  background: #e85729;
  border-radius: 12px 0px 12px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  top: -17px;
  left: 0;
}

.kuai2{
  background: #ffffff;
  border: 1px solid #ededf5;
  border-radius: 6px;
  margin: 10px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding: 5px 45px 5px 45px;
}
.kuai2:hover{
  background: #FFFAF2;
  border-color: #FAD08D;
}
.kuai3{
  background: #FFFAF2;
  border-color: #FAD08D;
  color: #A15100;
}

.kuaiyuansu{
  background: #ffffff;
  border: 1px solid #ededf5;
  border-radius: 6px;
  margin-right: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding: 30px 50px 30px 50px;
}
.kuaiyuansu:hover{
  background: #FFFFFF;
  border-color: #FAD08D;
}
.kuaiyuansu1{
  background: #FFFFFF;
  border-color: #FAD08D;
}
</style>
